var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
    _c("div", { staticClass: "inputs-container" }, [
      _c(
        "div",
        { staticClass: "inputs-container__container" },
        [
          _c(
            "label",
            {
              staticClass: "inputs-container__label",
              attrs: { for: "taxIdentification" },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("myCompanies.modals.createGroup.taxIdentification")
                )
              ),
            ]
          ),
          _c("InputValidation", {
            attrs: { rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      staticClass:
                        "inputs-container__input inputs-container__input--medium",
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        id: "taxIdentification",
                        placeholder: _vm.$t(
                          "myCompanies.modals.createGroup.taxIdentification"
                        ),
                      },
                      model: {
                        value: _vm.localForm.nationalRegister,
                        callback: function ($$v) {
                          _vm.$set(_vm.localForm, "nationalRegister", $$v)
                        },
                        expression: "localForm.nationalRegister",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "inputs-container__container inputs-container__container--full",
        },
        [
          _c(
            "label",
            {
              staticClass: "inputs-container__label",
              attrs: { for: "companyName" },
            },
            [
              _vm._v(
                _vm._s(_vm.$t("myCompanies.modals.createGroup.companyName"))
              ),
            ]
          ),
          _c("InputValidation", {
            attrs: { rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function ({ errors }) {
                  return [
                    _c("InputText", {
                      staticClass:
                        "inputs-container__input inputs-container__input--full",
                      class: { "p-invalid": errors[0] },
                      attrs: {
                        id: "companyName",
                        placeholder: _vm.$t(
                          "myCompanies.modals.createGroup.companyName"
                        ),
                      },
                      model: {
                        value: _vm.localForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.localForm, "name", $$v)
                        },
                        expression: "localForm.name",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "inputs-container" },
      [
        _c(
          "div",
          { staticClass: "inputs-container__container" },
          [
            _c(
              "label",
              {
                staticClass: "inputs-container__label",
                attrs: { for: "state" },
              },
              [_vm._v(_vm._s(_vm.$t("state")))]
            ),
            _c("InputValidation", {
              attrs: { rules: "required" },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function ({ errors }) {
                    return [
                      _vm.states.length > 0
                        ? _c(
                            "el-select",
                            {
                              staticClass:
                                "inputs-container__input inputs-container__input--medium",
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                id: "state",
                                placeholder: _vm.$t("select"),
                                filterable: "",
                                "no-match-text": "Sem dados encontrados",
                                "no-data-text": "Sem dados",
                                "loading-text": "Carregando...",
                                loading: _vm.states.length === 0,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getCity($event)
                                },
                              },
                              model: {
                                value: _vm.localForm.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localForm, "state", $$v)
                                },
                                expression: "localForm.state",
                              },
                            },
                            _vm._l(_vm.states, function (state, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: state.name, value: state.name },
                              })
                            }),
                            1
                          )
                        : _c("InputText", {
                            staticClass:
                              "inputs-container__input inputs-container__input--medium",
                            class: { "p-invalid": errors[0] },
                            attrs: {
                              id: "state",
                              placeholder: _vm.$t("select"),
                            },
                            model: {
                              value: _vm.localForm.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.localForm, "state", $$v)
                              },
                              expression: "localForm.state",
                            },
                          }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "inputs-container__container" },
          [
            _c(
              "label",
              {
                staticClass: "inputs-container__label",
                attrs: { for: "city" },
              },
              [_vm._v(_vm._s(_vm.$t("city")) + " ")]
            ),
            _c("InputValidation", {
              attrs: { rules: "required" },
              scopedSlots: _vm._u([
                {
                  key: "input",
                  fn: function ({ errors }) {
                    return [
                      _vm.cities.length > 0
                        ? _c(
                            "el-select",
                            {
                              staticClass:
                                "inputs-container__input inputs-container__input--medium",
                              class: { "p-invalid": errors[0] },
                              attrs: {
                                id: "city",
                                placeholder: _vm.$t("select"),
                                filterable: "",
                                "no-match-text": "Sem dados encontrados",
                                "loading-text": "Carregando...",
                                "no-data-text": "Selecione o Estado",
                              },
                              model: {
                                value: _vm.localForm.city,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localForm, "city", $$v)
                                },
                                expression: "localForm.city",
                              },
                            },
                            _vm._l(_vm.cities, function (city) {
                              return _c("el-option", {
                                key: city.name,
                                attrs: { label: city.name, value: city.name },
                              })
                            }),
                            1
                          )
                        : _c("InputText", {
                            staticClass:
                              "inputs-container__input inputs-container__input--medium",
                            class: { "p-invalid": errors[0] },
                            attrs: {
                              id: "state",
                              placeholder: _vm.$t("select"),
                            },
                            model: {
                              value: _vm.localForm.city,
                              callback: function ($$v) {
                                _vm.$set(_vm.localForm, "city", $$v)
                              },
                              expression: "localForm.city",
                            },
                          }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._t("button"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }