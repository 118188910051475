
import mixins from 'vue-typed-mixins';
import FormMixin from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/FormMixin';
import {
  City, Country, ICity, IState, State,
} from 'country-state-city';
import { ForeignEstablishmentForm } from './types';

export default mixins(FormMixin).extend({
  name: 'ForeignEstablishmentForm',
  props: {
    country: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      localForm: {
        establishmentType: 'STANDARD',
      } as ForeignEstablishmentForm,
      states: [] as IState[],
      cities: [] as ICity[],
      countryCode: '',
    };
  },
  mounted() {
    this.countryCode = Country.getAllCountries().find(
      (data) => data.name === this.country,
    )?.isoCode as string;
    this.states = State.getStatesOfCountry(this.countryCode);
  },
  methods: {
    async getCity(stateName: string) {
      this.cities = [];
      this.localForm.city = '';
      this.$emit('update:form', this.localForm);
      const state = this.states.find((value) => value.name === stateName);
      const cities = City.getCitiesOfState(this.countryCode, state?.isoCode as string);
      this.cities = cities;
    },
  },
  watch: {
    localForm: {
      deep: true,
      handler(data) {
        if (!data.taxRegime) {
          this.localForm.taxRegime = 'foreign';
          this.localForm.retreat = 'foreign';
          this.localForm.polling = 'foreign';
        }
        this.$emit('update:form', data);
      },
    },
    country(newCountry) {
      this.countryCode = Country.getAllCountries().find(
        (data) => data.name === newCountry,
      )?.isoCode as string;
      this.states = State.getStatesOfCountry(this.countryCode);
      this.cities = [];
      this.localForm.city = '';
      this.localForm.state = '';
      this.$emit('update:form', this.localForm);
    },
  },
});
